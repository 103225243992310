import {mapGetters, mapMutations} from "vuex";


//sections


export default {
  name: "characteristics",
  components: {},
  data() {
    return {
      collection: []
    }
  },
  created() {

  },
  mounted() {
    document.title = `${this.currentProduct.title +' - '+ this.currentProduct.additionalTabs.data[0].title}  | karbosnab`
  },
  computed: {
    ...mapGetters({
      currentProduct: 'catalog/currentProduct'
    })
  },

  methods: {
    ...mapMutations({})
  }
}
